import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from '../styles/page.module.css'

import React from "react"

export default function API() {
	return (
		<Layout isNavigationTransparent={false} isHomePage={false}>
			<SEO
				title="Poirot Features"
				description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
				keywords={[
					`Poirot`,
					`Poirot Features`,
					`Email Lookup Tool`,
					`Email Finder`,
					`Email Checker`,
				]}
			/>
					
			<div className={styles.servicePageWrapper}>
				<div className="sectionWrapper980">

					<h2>Poirot API Access</h2>
					<h3>Email  Verification</h3>
					<p>
						Request type: POST<br />
						Endpoint URL: https://api.poirot.app/verify<br />
						Request body fields (JSON):<br />
						apiKey: String<br />
						email: String<br />
					</p>

					<h3>Domain search</h3>
					<p>
						Request type: POST<br />
						Endpoint URL: https://api.poirot.app/explore<br />
						Request body fields (JSON):<br />
						apiKey: String<br />
						company: String<br />
					</p>

					<h3>Email  Finder</h3>
					<p>	
						Request type: POST<br />
						Endpoint URL: https://api.poirot.app/find<br />
						Request body fields (JSON):<br />
						apiKey: String<br />
						company: String<br />
						name: String<br />
					</p>
					<p>
						apiKey is the token you create on Account > Profile page<br />
						company is string of either url or company description (searching by url is faster and much more precise)
					</p>
				</div>
			</div>
		</Layout>
	)
}